<template>
  <nav
    class="navbar navbar-expand-lg navbar-absolute"
    :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div
          class="navbar-toggle d-inline"
          :class="{ toggled: $sidebar.showSidebar }"
        >
          <button
            type="button"
            class="navbar-toggler"
            aria-label="Navbar toggle button"
            @click="toggleSidebar"
          >
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <base-dropdown
              tag="li"
              :menu-on-right="!$rtl.isRTL"
              title-tag="a"
              class="nav-item"
              menu-classes="dropdown-navbar"
            >
              <a
                slot="title"
                href="#"
                class="dropdown-toggle nav-link"
                aria-expanded="true"
              >
                <div class="photo bg-primary">
                  <img src="../../../public/img/anime3.png" />
                </div>
                <b class="caret d-none d-lg-block d-xl-block"></b>
                <p class="d-lg-none">Sair</p>
              </a>
              <li class="nav-link">
                <router-link class="nav-item dropdown-item" to="/profile"
                  >Perfil</router-link
                >
              </li>
              <li
                class="nav-link"
                v-show="this.$store.getters['manager/isManagerAdmin']"
              >
                <router-link class="nav-item dropdown-item" to="/config"
                  >Configurações</router-link
                >
              </li>
              <div class="dropdown-divider"></div>
              <li class="nav-link">
                <a
                  @click.prevent="logout()"
                  href="#"
                  class="nav-item dropdown-item"
                  >Sair</a
                >
              </li>
            </base-dropdown>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
import Modal from "@/components/Modal";
import { CollapseTransition } from "vue2-transitions";
import { mapActions } from "vuex";

export default {
  components: {
    CollapseTransition,
    Modal,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      const ROUTE_NAME_MAP = {
        home: "Início",
        dashboard: "Painel",
        profile: "Perfil",
        config: "Configurações",
        maps: "Mapa",
        stock: "Estoque",
        machine: "Máquina",
        machines: "Máquinas",
        createMachine: "Criar Máquina",
        machineEdit: "Atualizar Máquina",
        purchases: "Compras",
        products: "Produtos",
        productReplacement: "Reposição de Produtos",
        moviments: "Movimentações",
        report: "Relatório de Vendas",
        productEdit: "Atualizar Produto",
        createProduct: "Criar Produto",
        stockMetrics: "Métricas Estoque",
        stockInventories: "Inventários do Estoque",
        stockInventory: "Inventário do Estoque",
        createStockInventory: "Fazer inventário do estoque",
        editStockInventory: "Editar inventário do estoque",
        machineInventories: "Inventários das máquinas",
        machineInventory: "Inventário da máquina",
        createMachineInventory: "Fazer inventário da máquina",
        editMachineInventory: "Editar inventário da máquina",
      };
      return this.capitalizeFirstLetter(ROUTE_NAME_MAP[name]);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    ...mapActions({
      LogOut: "auth/LogOut",
      clearManager: "manager/clearManager",
      clearCompany: "company/clearCompany",
    }),
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.clearManager();
      this.clearCompany();
      this.LogOut();
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style></style>
