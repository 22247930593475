<template>
  <component
    :is="tag"
    @click.native="hideSidebar"
    class="nav-item"
    :class="{ sublink: sublink }"
    v-bind="$attrs"
    tag="li"
  >
    <a class="nav-link">
      <slot>
        <i v-if="!sublink" :class="icon"></i>
        <p>{{ name }}</p>
      </slot>
    </a>
  </component>
</template>
<script>
export default {
  name: "sidebar-link",
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true,
    },
    addLink: {
      default: () => {},
    },
    removeLink: {
      default: () => {},
    },
  },
  props: {
    name: String,
    icon: String,
    tag: {
      type: String,
      default: "router-link",
    },
    sublink: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideSidebar() {
      if (this.autoClose) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains("active");
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
};
</script>
<style lang="css" scoped>
.nav-item.sublink {
  margin-left: 1rem;
}

.nav-item.sublink .nav-link p {
  line-height: 16px !important;
  -moz-text-wrap: wrap;
  text-wrap: wrap !important;
  position: relative;
  margin-left: 20px;
}

.nav-item.sublink .nav-link p::before {
  content: "";
  position: absolute;
  display: block;
  width: 10px;
  height: 20px;
  top: -12px;
  left: -20px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
}
.nav-link::before {
  display: none;
}
</style>
